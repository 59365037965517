import React, { useState, useEffect, Fragment } from "react"
import classnames from "classnames"

/* Import Fonts */
import "~assets/fonts/standard-book/standard-book.css"
import "~assets/fonts/pvc-banner-ultra/pvc-banner-ultra.css"

/* Import Normalize */
import "normalize.css"

/* Import Global Reset*/
import "~styles/reset.css"

/* Import Global Styles */
import "~styles/app.css"

/* Import Local STyles */
import "./index.css"

// example mailto body text
// Testing%20how%20the%20body%20looks%20when%20it%20gets%20generated%0AA%20second%20Line%0AA%20third%20line

/* Import Global Components */
import SEO from "~components/seo"

export default function Home() {
  const [isLoaded, setIsLoaded] = useState(false)
  const addCustomBodyCopy = false
  // each string in array is one line of body copy
  const date = "February 15, 2021"
  const bodyCopy = [
    `Sometimes it's easier to start with something already on the page, so here's a poem generated on ${date} that you can share with me if you'd like, or just keep it for yourself.`,
    "",
  ]

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true)
    }, 100)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const encodeBodyCopy = bodyCopy => {
    // replace spaces with %20
    const bodyCopySpacesEncoded = bodyCopy.map(bodyCopyLine => {
      return bodyCopyLine.replace(" ", "%20")
    })
    // concatenate array and replace line returns with %0AA
    const bodyCopyArrayReduced = bodyCopySpacesEncoded.reduce(
      (accumulator, currentValue) => {
        return (accumulator += `${currentValue}%0A`)
      },
      ""
    )
    return bodyCopyArrayReduced
  }

  const generatePoem = () => {}

  return (
    <Fragment>
      <SEO />
      <div class={classnames("overlay", { loaded: isLoaded })} />
      <main>
        <div className="role">
          <p>Designer and Developer</p>
          <a
            href={`mailto:ianglover@ianglover.com?body=${encodeBodyCopy(
              addCustomBodyCopy ? bodyCopy : [""]
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            <p>ianglover@ianglover.com</p>
          </a>
        </div>
        <div className="role">
          <p>Co-Founder</p>
          <a href="https://bladestudy.net" target="_blank" rel="noreferrer">
            <p className="pvc">BLADE STUDY</p>
          </a>
        </div>
      </main>
      <footer>
        <a
          href="https://en.wikipedia.org/wiki/Unix_time#Notable_events_in_Unix_time"
          target="_blank"
          rel="noreferrer"
        >
          <p>{new Date().valueOf()}</p>
        </a>
      </footer>
    </Fragment>
  )
}
